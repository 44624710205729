<template>
<div class="relative">
  <div class="min-h-screen fixed rounded-xl w-80 z-0 left-0 overflow-scroll hidden lg:flex items-start justify-left bg-beige py-4">
    <div class="overflow-scroll w-80 p-4 bg-beige fixed z-0">
      <ul class="overflow-scroll w-full" v-if="user">
        <nav-link name="Dashboard" :link="`/${user.username}/dashboard`" :selected="route.name == 'dashboard'" />
        <br>
        <li class="my-px group" >
          <span
            class="flex font-medium text-sm text-gray-400 px-4 my-2 uppercase"
            >Classes</span
          >
        </li>
        <nav-link name="Find Tutors" :link='`/${user.username}/tutors`' :selected="route.name == 'tutors'"  />
        <nav-link name="Sessions" :link="`/${user.username}/sessions`"  :selected="route.name == 'Sessions'"  />
        <br>
        <li class="my-px group" >
          <span
            class="flex font-medium text-sm text-gray-400 px-4 my-2 uppercase"
            >Personal</span
          >
        </li>
        <nav-link name="Wallet" :link="`/${user.username}/finance`"  :selected="route.name == 'Finance'"  />
        <nav-link name="Profile" :link="`/${user.username}/profile`" :selected="route.name == 'profile'" />
        <br>
        <li class="my-px group" >
          <span
            class="flex font-medium text-sm text-gray-400 px-4 my-2 uppercase"
            >Settings</span
          >
        </li>
        <nav-link name="Settings" :link="`/${user.username}/profile/edit`" :selected="route.name == 'EditProfile'"  />
        <nav-link name="Help" :link='`/${user.username}/help`' :selected="route.name == 'Help'" />
      </ul>
    </div>
  </div>

</div>
</template>

<script>
import { useRoute } from 'vue-router';
import { mapState } from 'vuex';
import NavLink from './NavLink.vue';
export default {
  setup() {
    const route = useRoute();
    return { route }
  },
  computed: {
    ...mapState(['user'])
  },
  components: { NavLink },};
</script>
