<template>
    <li class="my-px group " @click="router.push(`${link}`)">
        <button
        class="flex w-full flex-row text-lg items-center h-12 p-4 rounded-lg text-shark group-hover:text-orange group-hover:bg-orange-tint"
        :class="{'text-orange': selected, 'bg-orange-tint': selected, }"
        >
        <!-- <span
            class="flex items-center justify-center text-lg text-gray-400 "
            :class="{'text-orange': selected }"
        >
            <svg
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="h-6 w-6 fill-current text-gray-700 group-hover:text-orange"
            :class="{'text-orange': selected }"
            >
            <path
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
            ></path>
            </svg>
        </span> -->
        <span class="group-hover:text-orange font-bold" :class="{'text-orange': selected}">{{name}}</span>
        <span v-if="notification != 0"
            class="flex items-center justify-center text-sm font-semibold group-hover:bg-orange  bg-orange text-white h-6 px-2 rounded-full ml-auto"
            >{{notification}}</span
        >
        </button>
    </li>
</template>

<script>

import { useRouter } from "vue-router";

    export default {
        setup() {
            const router = useRouter();
            return { router };
        },
        props: {
            name: String,
            link: String,
            svg: String,
            selected: Boolean,
            notification: {
                type: Number,
                default: 0
            }

        }
    }
</script>